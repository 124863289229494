import styles from "./index.module.css";
import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

class RefitPartnerPage extends React.Component {
  constructor(props) {
    super(props);

    const { companyAlias } = props.params;

    if (!companyAlias) {
      window.location = process.env.REACT_APP_VIVENDA_URL;
    }

    this.state = {
      companyId: null,
      companyAlias: companyAlias,
    };
  }

  componentDidMount() {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          `company/get-id-by-alias/${this.state.companyAlias}`,
        {
          headers: {
            "x-api-key":
              "#sEwm83&!oZ[4EXKi[#a[.@6x?{IGOM(I+X1Dzm)C-i+hl$~Dl`_IvKbr5Iw-]aiDg8n19N[)#euKGvLD}BG~(,N3rS1l;Fdr:tXn#;zFwGQ'k.H'MS3)Qah",
          },
        }
      )
      .then((result) => {
        const companyId = result.data?.id;

        if (!result || !companyId) {
          window.location = process.env.REACT_APP_VIVENDA_URL;
        }
        this.setState({
          companyId: companyId,
          companyAlias: this.state.companyAlias,
        });
      })
      .catch((err) => {
        window.location = process.env.REACT_APP_VIVENDA_URL;
      });
  }

  render() {
    return (
      <div className={styles.page}>
        <div className={styles["top-container"]}>
          <h1 className={styles["title"]}>
            Somos parceiros da
            <br />
            Vivenda
          </h1>
          <div className={styles["vertical-border"]} />
          <img
            src="logo.png"
            className={styles["top-image"]}
            alt="Logo da Nova Vivenda"
          />
        </div>
        <iframe
          title={"iframe"}
          src={`${process.env.REACT_APP_FORM_URL}?companyId=${this.state.companyId}`}
          className={styles.formIframe}
          frameBorder="0"
        />
      </div>
    );
  }
}

export default (props) => <RefitPartnerPage {...props} params={useParams()} />;
