import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import RefitPartnerPage from "./Components/RefitPartnerPage";
import reportWebVitals from "./reportWebVitals";

import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/:companyAlias" element={<RefitPartnerPage />} />
        <Route index element={<RefitPartnerPage />} />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
